.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.image-container {
  text-align: center; /* Optional: Center the image horizontally */
}

.responsive-image {
  width: 100%; /* Full width by default */
  height: auto; /* Maintain aspect ratio */

  /* Media query for desktop view */
  @media (min-width: 768px) {
    max-width: 500px; /* Limit image width on larger screens */
  }

  /* Media query for mobile view */
  @media (max-width: 767px) {
    max-width: 100%; /* Full width on smaller screens */
  }
}

.under-construction {
  text-align: center;
  margin: 50px auto 0px auto;
  max-width: 600px;
}

.under-construction h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.under-construction p {
  font-size: 16px;
  color: #777;
}

.spinner {
  margin-top: 20px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  animation: spin 1s linear infinite;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}